<template>
<b-container fluid>
    <b-row>
        <b-col sm="12">
          <iq-card className="book-detail" bodyClass="p-0">
            <template v-slot:body>
              <iframe :src="dataBuku.nama_file"  style="width: 100%; height: 90vh;"></iframe>
            </template>
          </iq-card>
        </b-col>
    </b-row>
</b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'Bookpdf',
  mounted () {
    core.index()
    this.loadData()
  },
  computed: {
    ...mapGetters({
      lang: 'Setting/langState'
    })
  },
  watch: {
  },
  methods: {
    loadData (){
      axios.get(this.baseapi + "buku/" + this.$route.params.id, {
        headers: {
            // Authorization: `Bearer `+this.user.accessToken
        }
      }).then(response => {
          this.dataBuku = response.data.data;
      }).catch(error => {
          console.log(error)
          return error
          });
      }
  },
  data () {
    return {
      pdf: require('../../assets/pdf/book.pdf'),
      dataBuku: [],
      baseapi: localStorage.getItem("baseapi")
    }
  }
}
</script>